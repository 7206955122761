
import { defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import {
  ReceiptBaseResponse,
  ReportResponseBase,
} from '@/core/interfaces/ApiResponses';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';

import ReceiptList from '@/views/crafted/pages/receipts/receipt-list/ReceiptList.vue';
import PhieuXuatKhoService from '@/core/services/Receipt.service';

export default defineComponent({
  name: "list-receipts-by-report",
  components: { ReceiptList },
  setup() {
    const route = useRoute();

    const report = ref<ReportResponseBase>({
      id: -1,
      id_ke_hoach_sua_chua: -1,
      so_bien_ban: "",
    });
    const receipts = ref<ReceiptBaseResponse[]>([]);

    onMounted(async () => {
      setCurrentPageTitle("Trang quản lý biên bản");
      const {
        data: { data },
      } = await PhieuXuatKhoService.listByReport(+route.params.id);
      receipts.value = [...data];
      if (receipts.value[0].bien_ban_giai_the !== null) {
        report.value = { ...receipts.value[0].bien_ban_giai_the };
      }
    });

    return {
      receipts,
      report,
    };
  },
});
