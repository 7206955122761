
import { computed, defineComponent, PropType } from "vue";
import moment from "moment";

import { ReceiptBaseResponse } from "@/core/interfaces/ApiResponses";
export default defineComponent({
  name: "receipt-list",
  props: {
    items: {
      type: Array as PropType<Array<ReceiptBaseResponse>>,
    },
    reportId: {
      type: Number,
      default: -1,
    },
  },
  setup() {
    return {
      formatDate: computed(() => moment),
    };
  },
  methods: {
    receiptDetailUrl(receiptId: number) {
      return `/quan-ly/bien-ban-giai-the/${this.reportId}/phieu-xuat/chi-tiet/${receiptId}`;
    },
  },
});
